<!-- Finder de Grupos -->

<template>
  <div class="grupos_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">       
          <base_Header 
            :Entorno="Entorno.header"
            @onEvent="event_Header">          
          </base_Header>
        </div>

        <!-- Contenido -->     
        <div class="conflex">          
          <div style="width:60%;padding:10px">          
            <v-text-field
              v-bind="$input"
              v-model="txt"
              label="Búsqueda"
              append-icon="mdi-magnify"
              @keypress.13="filterGrupos"
              @click:append="filterGrupos">
            </v-text-field>
          </div>
    
          <!-- Grid -->
          <base_Fgrid 
            style="width:100%"
            :padre="stName" 
            :Entorno="Entorno" 
            show-expand
            :items-per-page=-1 
            @onEvent="event_Grid">         

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">    
            
              <div style="padding:10px 0 0 10px;">
                <btracombi                      
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>


            <!-- Slot panel expansible -->
            <template v-slot:expansible_ctrls="{}">             
              <grupos_XM
                :padre="stName"
                :accion="accion"
                :accionRow="rowSelected"               
                :disparo="disparo"            
                @onEvent="event_expansible">           
              </grupos_XM>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>  
    </div>    
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const grupos_XM = () => plugs.groute("grupos_XM.vue", "comp");

  export default {
    mixins: [mixinFinder],
    components: { base_Fgrid, base_Header, btracombi, grupos_XM },
    props: {
      padre: { type:String, default: ''},     
      componenteTipo: { type:String, default:'FM' },      
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return { 
        schema:null,             
        api:'grup_F',
        stName:'stFgrupos',
        disparo:false,
        accion:'0',
             
        headers: {},
        txt:''        
      };
    },

    methods: {
      ini_data() {      
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;
    
        // entorno
        this.Entorno.grid.autoload= true;
        this.Entorno.header.titulo= 'Grupos';
        this.Entorno.header.header= true;
        this.Entorno.grid.row.expansible= true;
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.FM));  
        
        this.headers = {
          header:[          
            { text: "Nombre", value: "name", witdh: "100%" },                 
          ]
        };

        this.Entorno.grid.headers = this.set_headers();      
      },


      // filtro registros por lo indicado en el campo txt
      filterGrupos() {       
        this.$store.commit(this.stName + '/data2State', { prop:'records', value:this.miSt.read_records.slice().filter(elem=> {
                  
          return (
            elem.name.toLowerCase().includes(this.txt.toLowerCase())
          )          

        })})

      }

    
    }
  };
</script>
